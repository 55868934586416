<template>
  <div class="header-container">
    <div class="header-content">
      <div class="logo" @click="goIndex">
        <img src="../assets/logo.png" alt="" />
        <span>梦跳动找房</span>
        <span class="nanjin-icon"><i class="el-icon-location"></i>南京</span>
      </div>
      <div
        class="title-wrapper"
        :class="{ 'header-container-left': type === 2 }"
      >
        <ul :class="{ 'index-color': currentI }">
          <li @click="$router.push({ path: '/Index' })">首页</li>
          <li
            @click="doChangeType(1)"
            :class="{ 'li-active': currentR && active === 1 }"
          >
            二手房
          </li>
          <li
            @click="doChangeType(2)"
            :class="{ 'li-active': currentR && active === 2 }"
          >
            租房
          </li>
          <li
            @click="doToChange(3, 'house-map')"
            :class="{ 'li-active': currentR && active === 3 }"
          >
            房源地图
          </li>
          <li
                  @click="doToChange(5, 'house-deal')"
                  :class="{ 'li-active': currentR && active === 5 }"
          >
            房源成交量
          </li>
          <li
            @click="doToChange(4, 'house-change')"
            :class="{ 'li-active': currentR && active === 4 }"
          >
            房源变化
          </li>
          <li @click="doDownloadApp">下载APP</li>
        </ul>
      </div>
      <el-popover width="250" trigger="click" @show="doShowPopover">
        <div class="user-info-tan">
          <div class="user-info-left">
            <img
              style="cursor: pointer"
              @click="$router.push({ path: '/user-account' })"
              :src="userInfo.avatar"
              alt=""
            />
            <p class="name" @click="$router.push({ path: '/user-account' })">
              {{ loginName }}
            </p>
            <p>
              <span>等级：</span>
              <span>vip{{ userInfo.userLevel }}</span>
            </p>
          </div>
          <div class="user-info-right">
            <p @click="doLoginout" style="cursor: pointer; user-select: none">
              <i class="el-icon-s-finance" style="margin-right: 15px"></i>退出
            </p>
            <p>
              <el-button
                :disabled="userInfo && userInfo.isSign === 0"
                type="primary"
                size="mini"
                @click="doSign"
                >{{
                  userInfo && userInfo.isSign === 0 ? "已签到" : "签到"
                }}</el-button
              >
            </p>
            <p class="qiandao">
              已签到<span>{{ (userInfo && userInfo.signCount) || 0 }}</span
              >天
            </p>
          </div>
        </div>
        <div class="qinadao-line">
          <span class="width">{{ userInfo.userLvName || 1 }}</span>
          <el-progress
            v-if="userInfo"
            style="dispaly: inline-block"
            :percentage="userInfo.userLvRate"
          ></el-progress>
          <span>{{ userInfo.lastLvName }}</span>
        </div>
        <div
          :class="{ 'index-color': currentI }"
          slot="reference"
          class="login-register"
          @click.stop="goLogin"
        >
          <i class="el-icon-user icon" />
          <span>{{ loginName }}</span>
          <i
            v-if="isLogin"
            style="margin-left: 5px"
            class="el-icon-arrow-down"
          ></i>
        </div>
      </el-popover>
    </div>
    <div>
      <el-input
        v-if="search"
        class="header-search-input"
        :placeholder="placeholder"
        v-model="keywords"
      >
        <i @click="doSearch" slot="suffix" class="el-icon-search"></i>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    type: {
      type: Number,
      default: 2, // 1 正常 2 居左
    },
    search: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    info: {
      handler(val) {
        if (val) {
          this.keywords = val.search;
          this.active = Number(val.active);
        }
      },
      deep: true,
    },
  },
  mounted() {
    const loginName = localStorage.getItem("userName");
    this.avatar = localStorage.getItem("avatar");
    if (loginName) {
      this.loginName = loginName;
      this.isLogin = true;
    }
  },
  computed: {
    currentR() {
      return (
        this.$route.path === "/house-list" ||
        this.$route.path === "/house-change" ||
        this.$route.path === "/house-map"
      );
    },
    currentI() {
      return this.$route.path === "/Index";
    },
  },
  data() {
    return {
      loginName: "登录/注册",
      avatar: "",
      isLogin: false,
      keywords: "",
      active: 1,
      userInfo: {
        avatar: "",
      },
      placeholder: "请输入区域或小区名开始找房",
    };
  },
  methods: {
    doSearch() {
      this.$emit("search", this.keywords);
    },
    doToChange(type, path) {
      this.$router.push({
        path: path,
        query: { search: "", active: type },
      });
    },
    doChangeType(type) {
      if (this.$route.path !== "/house-list") {
        this.$router.push({
          path: "house-list",
          query: { search: "", active: type },
        });
      } else {
        this.active = type;
        this.$emit("update-active", type);
      }
    },
    doDownloadApp() {
      this.$message.info("此功能尚未开放，敬请期待！");
    },
    doSign() {
      this.$request.post("/api/user/addSign").then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.$emit("do-sign");
        } else {
          this.$message.warning(res.msg);
        }
        this.doShowPopover();
      });
    },
    doShowPopover() {
      this.$request.get("/api/user/getUserInfo").then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
        }
      });
    },
    doLoginout() {
      localStorage.clear();
      this.$router.push({ path: "/login-register" });
    },
    goIndex() {
      this.$router.push({ path: "/Index" });
    },
    goLogin() {
      if (!this.isLogin) {
        this.$router.push({ path: "/login-register" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variable.scss";
.header-container {
  padding: 20px 0;
  .header-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    cursor: pointer;
    img {
      width: 60px;
      height: 40px;
      vertical-align: middle;
      margin-right: 10px;
    }
    span {
      font-size: 28px;
      font-weight: 400;
      color: $mainColor;
    }
    .nanjin-icon {
      font-size: 14px;
      padding: 2px 5px;
      border: 2px solid #ffffff;
      border-radius: 20px;
      margin-left: 10px;
      color: #ffffff;
      vertical-align: middle;
    }
  }
  .title-wrapper {
    flex: 1;
    ul li {
      position: relative;
      color: $textColor;
      display: inline-block;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      margin-right: 10px;
      .icon {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    .li-active {
      &::after {
        content: "";
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $mainColor;
      }
    }
  }
}
.login-register {
  color: $textColor;
  cursor: pointer;
  .icon {
    margin-right: 10px;
  }
}
.header-container-left {
  text-align: left;
  margin-left: 50px;
}
.header-search-input {
  width: 42%;
  margin-top: 20px;
  .el-input__inner {
    font-size: 18px;
    height: 45px;
    border-radius: 25px;
    border-color: $mainColor;
  }
  .el-input__suffix {
    font-size: 28px;
    line-height: 45px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.user-info-tan {
  display: flex;
  justify-content: space-between;
}
.qinadao-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-info-left {
  text-align: center;
  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .name {
    margin-top: 10px;
    cursor: pointer;
  }
}
.user-info-right {
  margin-left: 20px;

  button {
    margin-top: 30px;
    border: none;
    outline: none;
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 15px;
    background-color: $mainColor;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: $mainColor;
    }
  }
  .qiandao {
    margin: 10px 0;
    font-size: 12px;
    span {
      color: $mainColor;
    }
  }
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  background-color: $mainColor;
  border-color: $mainColor;
}
.index-color {
  color: #fff !important;
  li {
    color: #fff !important;
    font-weight: 200 !important;
  }
}
</style>
